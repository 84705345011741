<template>
  <el-dialog
    :title="$t('bianji')"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="closedialog"
    @open="open"
    :before-close="handleClose"
  >
    <el-form
      ref="editref"
      :model="editform"
      :rules="formrules"
      label-width="120px"
    >
      <el-form-item :label="$t('cp.cpmc')" prop="title">
        <el-input v-model="editform.title"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cp.cplx')" prop="productid">
        <el-select v-model="editform.productid" :placeholder="$t('qxz')">
          <el-option
            v-for="item in product"
            :key="item.id"
            :label="item.product_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('cp.zwjj')" prop="cndesc">
        <el-input v-model="editform.cndesc"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cp.ywjj')" prop="endesc">
        <el-input v-model="editform.endesc"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cp.fxsj')" prop="publishtime">
        <el-date-picker
          v-model="editform.publishtime"
          type="date"
          :placeholder="$t('qxz')"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item :label="$t('cp.fxsl')" prop="publishnum">
        <el-input v-model="editform.publishnum"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cp.fkbdfw')" required>
        <el-input v-model="editform.mix">
          <template slot="prepend">{{ $t('cp.zxz') }}</template>
        </el-input>
        <el-input v-model="editform.max">
          <template slot="prepend">{{ $t('cp.zdz') }}</template>
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('cp.sjbdfw')" prop="rand">
        <el-input v-model="editform.rand"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cp.cpdm')" prop="productcode">
        <el-input v-model="editform.productcode"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cp.suoxie')" prop="abbr">
        <el-input v-model="editform.abbr"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cp.ysdm')" prop="colorcode">
        <el-input v-model="editform.colorcode"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cp.sjwfjg')" prop="times">
        <el-input v-model="editform.times"></el-input>
        <el-alert :title="$t('cp.sjtips')" type="error" :closable="false">
        </el-alert>
      </el-form-item>
      <el-form-item :label="$t('cp.zdtzje')" prop="minimum">
        <el-input v-model="editform.minimum"></el-input>
        <el-alert :title="$t('cp.zdtips')" type="error" :closable="false">
        </el-alert>
      </el-form-item>
      <el-form-item :label="$t('最高投注金额')" prop="maximum">
        <el-input v-model="editform.maximum"></el-input>
        <el-alert :title="$t('cp.zgtips')" type="error" :closable="false">
        </el-alert>
      </el-form-item>
      <el-form-item :label="$t('固定下单金额')" prop="gudingjine">
        <el-input v-model="editform.gudingjine"></el-input>
        <el-alert :title="$t('cp.zgtips')" type="error" :closable="false">
        </el-alert>
      </el-form-item>
      <el-form-item :label="$t('cp.ykbl')" prop="ratio">
        <el-input v-model="editform.ratio"></el-input>
        <el-alert :title="$t('cp.yktips')" type="error" :closable="false">
        </el-alert>
      </el-form-item>
      <el-form-item :label="$t('cp.zhouyi')" prop="monday">
        <el-time-picker
          is-range
          v-model="editform.monday"
          range-separator="—"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          value-format="HH:mm:ss"
        >
        </el-time-picker>
      </el-form-item>
      <el-form-item :label="$t('cp.zhouer')" prop="tuesday">
        <el-time-picker
          is-range
          v-model="editform.tuesday"
          range-separator="—"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          value-format="HH:mm:ss"
        >
        </el-time-picker>
      </el-form-item>
      <el-form-item :label="$t('cp.zhousan')" prop="wednesday">
        <el-time-picker
          is-range
          v-model="editform.wednesday"
          range-separator="—"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          value-format="HH:mm:ss"
        >
        </el-time-picker>
      </el-form-item>
      <el-form-item :label="$t('cp.zhousi')" prop="thursday">
        <el-time-picker
          is-range
          v-model="editform.thursday"
          range-separator="—"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          value-format="HH:mm:ss"
        >
        </el-time-picker>
      </el-form-item>
      <el-form-item :label="$t('cp.zhouwu')" prop="friday">
        <el-time-picker
          is-range
          v-model="editform.friday"
          range-separator="—"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          value-format="HH:mm:ss"
        >
        </el-time-picker>
      </el-form-item>
      <el-form-item :label="$t('cp.zhouliu')" prop="saturday">
        <el-time-picker
          is-range
          v-model="editform.saturday"
          range-separator="—"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          value-format="HH:mm:ss"
        >
        </el-time-picker>
      </el-form-item>
      <el-form-item :label="$t('cp.zhouri')" prop="sunday">
        <el-time-picker
          is-range
          v-model="editform.sunday"
          range-separator="—"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          value-format="HH:mm:ss"
        >
        </el-time-picker>
      </el-form-item>
      <el-form-item :label="$t('bizhong') + '(from)'" prop="from">
        <el-input v-model="editform.from"></el-input>
      </el-form-item>
      <el-form-item :label="$t('bizhong') + '(to)'" prop="to">
        <el-input v-model="editform.to"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cp.sfxs')" prop="isshow">
        <el-radio-group v-model="editform.isshow">
          <el-radio label="1">{{ $t('shi') }}</el-radio>
          <el-radio label="0">{{ $t('fou') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('beizhu')" prop="remark">
        <el-input v-model="editform.remark"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">{{ $t('tijiao') }}</el-button>
      <el-button @click="handleClose">{{ $t('quxiao') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      product: [],
      editform: {
        title: '',
        productid: '',
        cndesc: '',
        endesc: '',
        publishtime: '',
        publishnum: '',
        mix: '',
        max: '',
        rand: '',
        productcode: '',
        abbr: '',
        colorcode: '',
        times: '',
        minimum: '',
        maximum: '',
        gudingjine: '',
        ratio: '',
        monday: ['00:00:00', '23:59:59'],
        tuesday: ['00:00:00', '23:59:59'],
        wednesday: ['00:00:00', '23:59:59'],
        thursday: ['00:00:00', '23:59:59'],
        friday: ['00:00:00', '23:59:59'],
        saturday: ['00:00:00', '23:59:59'],
        sunday: ['00:00:00', '23:59:59'],
        from: '',
        to: '',
        isshow: '1',
        remark: ''
      },
      // 表单验证对象
      formrules: {
        title: [{ required: true, message: '请填写产品名称', trigger: 'blur' }],
        productid: [
          { required: true, message: '请选择产品类型', trigger: 'change' }
        ],
        cndesc: [
          { required: true, message: '请填写中文简介', trigger: 'blur' }
        ],
        endesc: [
          { required: true, message: '请填写英文简介', trigger: 'blur' }
        ],
        publishtime: [
          { required: true, message: '请填写发行时间', trigger: 'blur' }
        ],
        publishnum: [
          { required: true, message: '请填写发行数量', trigger: 'blur' }
        ],
        rand: [
          { required: true, message: '请填写随机波动范围', trigger: 'blur' }
        ],
        productcode: [
          { required: true, message: '请填写产品代码', trigger: 'blur' }
        ],
        abbr: [{ required: true, message: '请填写缩写', trigger: 'blur' }],
        colorcode: [
          { required: true, message: '请填写颜色代码', trigger: 'blur' }
        ],
        times: [
          { required: true, message: '请填写时间玩法间隔', trigger: 'blur' }
        ],
        minimum: [
          { required: true, message: '请填写最低投注金额', trigger: 'blur' }
        ],
        maximum: [
          { required: true, message: '请填写最高投注金额', trigger: 'blur' }
        ],
        gudingjine: [
          { required: true, message: '请写入固定金额', trigger: 'blur' }
        ],
        ratio: [{ required: true, message: '请填写盈亏比例', trigger: 'blur' }],
        mondy: [{ required: true, message: '请填写周一时间', trigger: 'blur' }],
        tuesday: [
          { required: true, message: '请填写周二时间', trigger: 'blur' }
        ],
        wednesday: [
          { required: true, message: '请填写周三时间', trigger: 'blur' }
        ],
        thursday: [
          { required: true, message: '请填写周四时间', trigger: 'blur' }
        ],
        friday: [
          { required: true, message: '请填写周五时间', trigger: 'blur' }
        ],
        saturday: [
          { required: true, message: '请填写周六时间', trigger: 'blur' }
        ],
        sunday: [{ required: true, message: '请填写周日时间', trigger: 'blur' }]
      }
    }
  },
  props: ['visible', 'id'],
  methods: {
    // 关闭弹窗前操作
    handleClose() {
      this.$emit('close')
    },
    // 打开弹窗
    async open() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      // 请求产品分类数据
      const { data } = await this.$http.get(
        '/admin/product/editproduct/id/' + this.id
      )
      if (data) {
        if (data.code === 200) {
          this.product = data.data.product
          this.editform.title = data.data.productinfo.product_title
          this.editform.productid = data.data.productinfo.product_type_id
          this.editform.cndesc = data.data.productinfo.cn_description
          this.editform.endesc = data.data.productinfo.en_description
          this.editform.publishtime = data.data.productinfo.publishtime
          this.editform.publishnum = data.data.productinfo.publishnum
          this.editform.mix = data.data.productinfo.mix
          this.editform.max = data.data.productinfo.max
          this.editform.rand = data.data.productinfo.rand
          this.editform.productcode = data.data.productinfo.product_code
          this.editform.abbr = data.data.productinfo.abbr
          this.editform.colorcode = data.data.productinfo.color_code
          this.editform.times = data.data.productinfo.times
          this.editform.minimum = data.data.productinfo.minimum
          this.editform.maximum = data.data.productinfo.maximum
          this.editform.gudingjine = data.data.productinfo.gudingjine
          this.editform.ratio = data.data.productinfo.ratio
          this.editform.monday = data.data.productinfo.monday
          this.editform.tuesday = data.data.productinfo.tuesday
          this.editform.wednesday = data.data.productinfo.wednesday
          this.editform.thursday = data.data.productinfo.thursday
          this.editform.friday = data.data.productinfo.friday
          this.editform.saturday = data.data.productinfo.saturday
          this.editform.sunday = data.data.productinfo.sunday
          this.editform.from = data.data.productinfo.from
          this.editform.to = data.data.productinfo.to
          this.editform.isshow = data.data.productinfo.isshow
          this.editform.remark = data.data.productinfo.remark
        } else {
          this.$message.error(data.msg)
        }
      }
      loading.close()
    },
    // 关闭弹窗
    closedialog() {
      this.$refs.editref.resetFields()
    },
    // 提交操作
    submit() {
      this.$refs.editref.validate(async valid => {
        if (!valid) return false
        this.editform.id = this.id
        const { data } = await this.$http.post(
          '/admin/product/editproduct',
          this.editform
        )
        if (data) {
          if (data.code === 200) {
            this.$message.success('操作成功')
            this.handleClose()
            this.$emit('update')
          } else {
            this.$message.error(data.msg)
          }
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.el-alert {
  margin-top: 10px;
}
/*表单项宽度自适应  */
.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: 350px;
}
.el-date-editor.el-input,
.el-select > .el-input,
.el-date-editor.el-input__inner {
  width: 350px;
}
.el-form-item__content .el-input-group {
  width: 50%;
}
.mobile .el-form-item__content .el-input-group {
  width: 100%;
}
.mobile .el-date-editor--daterange.el-input,
.mobile .el-date-editor--daterange.el-input__inner,
.mobile .el-date-editor--timerange.el-input,
.mobile .el-date-editor--timerange.el-input__inner {
  width: 100%;
}
.mobile .el-date-editor.el-input,
.mobile .el-select > .el-input,
.mobile .el-date-editor.el-input__inner {
  width: 100%;
}
</style>
