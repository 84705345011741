<template>
  <div>
    <el-form :inline="true">
      <!-- 添加/搜索区域 -->
      <el-form-item>
        <el-button @click="add">{{ $t('xinzeng') }}</el-button>
      </el-form-item>
      <!-- 列表区域 -->
      <el-form-item>
        <el-input
          v-model="queryinfo.query"
          :clearable="true"
          @clear="getlist"
          style="width:240px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getlist"
          ></el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      :data="productlist"
      border
      style="width: 100%;"
    >
      <el-table-column prop="id" label="#"></el-table-column>
      <el-table-column
        prop="product_title"
        :label="$t('cp.cpmc')"
      ></el-table-column>
      <el-table-column
        prop="product_type_id"
        :label="$t('cp.cplx')"
      ></el-table-column>
      <el-table-column prop="mix" :label="$t('cp.zxz')"></el-table-column>
      <el-table-column prop="max" :label="$t('cp.zdz')"></el-table-column>
      <el-table-column
        prop="from"
        :label="$t('bizhong') + '(from)'"
      ></el-table-column>
      <el-table-column
        prop="to"
        :label="$t('bizhong') + '(to)'"
      ></el-table-column>
      <el-table-column prop="rand" :label="$t('cp.sjbdfw')"></el-table-column>
      <el-table-column
        prop="product_code"
        :label="$t('cp.cpdm')"
      ></el-table-column>
      <el-table-column prop="abbr" :label="$t('cp.suoxie')"></el-table-column>
      <el-table-column
        prop="color_code"
        :label="$t('cp.ysdm')"
      ></el-table-column>
      <el-table-column prop="times" :label="$t('cp.sjwfjg')"></el-table-column>
      <el-table-column
        prop="minimum"
        :label="$t('cp.zdtzje')"
      ></el-table-column>
      <el-table-column
        prop="maximum"
        :label="$t('cp.zgtzje')"
      ></el-table-column>
      <el-table-column prop="ratio" :label="$t('cp.ykbl')"></el-table-column>
      <el-table-column prop="gudingjine" :label="$t('固定金额')"></el-table-column>
      <el-table-column prop="remark" :label="$t('beizhu')"></el-table-column>
      <el-table-column :label="$t('caozuo')" width="150px">
        <template slot-scope="{ row }">
          <el-tooltip
            :content="$t('bianji')"
            placement="top"
            :enterable="false"
          >
            <i class="iconfont icon-bianji" @click="edit(row.id)"></i>
          </el-tooltip>
          <el-tooltip
            :content="$t('shanchu')"
            placement="top"
            :enterable="false"
          >
            <i class="el-icon-close" @click="del(row.id)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      :hide-on-single-page="total <= 10"
      background
      layout="prev, pager, next, total"
      :page-size="10"
      :total="total"
      :current-page="queryinfo.page"
      @current-change="changepage"
    ></el-pagination>
    <!-- 添加产品弹窗 -->
    <addproduct
      :visible="adddialogvisible"
      @close="adddialogvisible = false"
      @update="getlist"
    ></addproduct>
    <!-- 编辑产品弹窗 -->
    <editproduct
      :visible="editdialogvisible"
      @close="editdialogvisible = false"
      @update="getlist"
      :id="id"
    ></editproduct>
  </div>
</template>

<script>
import addproduct from '@/components/product/addproduct'
import editproduct from '@/components/product/editproduct'
export default {
  data() {
    return {
      // 分页请求数据对象
      queryinfo: {
        query: '',
        page: 1
      },
      total: 0,
      loading: true,
      // 产品列表对象
      productlist: [],
      adddialogvisible: false,
      editdialogvisible: false,
      // 编辑的产品id
      id: ''
    }
  },
  components: {
    addproduct,
    editproduct
  },
  created() {
    this.getlist()
  },
  methods: {
    async getlist() {
      const { data } = await this.$http.get('/admin/product/productlist', {
        params: this.queryinfo
      })
      this.productlist = data.data.list
      this.loading = false
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },
    // 新增
    add() {
      this.adddialogvisible = true
    },
    // 编辑
    edit(id) {
      this.id = id
      this.editdialogvisible = true
    },
    // 删除操作
    del(id) {
      this.$confirm(this.$t('shanchutips'), this.$t('tishi'), {
        confirmButtonText: this.$t('queding'),
        cancelButtonText: this.$t('quxiao'),
        type: 'warning'
      })
        .then(async () => {
          const { data } = await this.$http.post('/admin/product/delproduct', {
            id
          })
          if (data) {
            if (data.code === 200) {
              this.$message.success(data.msg)
              this.getlist()
            } else {
              this.$message.error(data.msg)
            }
          }
        })
        .catch(() => {
          // this.$message.info('已取消删除')
        })
    }
  }
}
</script>

<style lang="less" scoped>
.iconfont,
.el-icon-close {
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 15px;
  padding: 1px;
  &:hover {
    color: #409eff;
    cursor: pointer;
    border-color: #409eff;
  }
}
.el-select {
  min-width: 300px;
}
.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
}
.avatar-uploader:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
